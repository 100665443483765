/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>

      <div>
        <h3 class="documents-title">
          Estatus de conexión con el SAT:
          <b class="red-title" v-if="!satLink"> Sin conectar</b>
          <b class="green-title" v-if="satLink"> Cuenta conectada</b>
        </h3>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de documentos: <b>{{ formattedDocuments.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="formattedDocuments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="incomesaDocuments"
        :checked-rows.sync="checkedClients"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.ID_IDENTIFICACION}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        scrollable
        default-sort="ID_IDENTIFICACION"
      >
        <template #empty>
          <div class="has-text-centered">No hay documentos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] | money("MXN", 2) }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button type="is-primary" @click="showXML(props.row._id)"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openDocumentDetails(props.row)"
                    v-if="props.row.DOC_DET && props.row.DOC_DET.length > 0"
                    >Ver detalles</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openDocumentPayments(props.row)"
                    v-if="props.row.PAGOS && props.row.PAGOS.length > 0"
                    >Ver pagos</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openDocumentPayroll(props.row)"
                    v-if="props.row.NOMINA && typeName === 'Nómina'"
                    >Ver detalles de nomina</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import SatDoumentsPayments from "./SatDoumentsPayments";
import SatDoumentsDetails from "./SatDoumentsDetails";
import SatDocumentsPayroll from "./SatDocumentsPayroll";
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SatDocumentsList",
  props: ["typeName", "documents"],
  directives: { cleave },
  data() {
    return {
      moment: moment,
      perPage: 50,
      checkedClients: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "ID_IDENTIFICACION",
          label: "Id",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FOLIO",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "dateFormatted",
          label: "Fecha",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "statusFormatted",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },

        {
          field: "typeFormatted",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ENVIA",
          label: "Nombre del emisor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_RECEPTOR",
          label: "Nombre del receptor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ESTADO_CAN",
          label: "Estado de cancelación",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "cancelationUpdateDateFormatted",
          label: "Fecha de actualización de estado de cancelación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateUpdate,
        },
        {
          field: "certificationDateFormatted",
          label: "Fecha de certificación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDateCertification,
        },
        {
          field: "TIPO_PAGO",
          label: "Tipo de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "METODO_PAGO",
          label: "Método de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "USO",
          label: "Uso",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CP",
          label: "Código postal",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "MONEDA_CAMBIO",
          label: "Tipo de cambio",
          sortable: true,
          searchable: true,
          display: true,
        },

        {
          field: "SUBTOTAL",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL_IMPUESTOS",
          label: "Total de impuestos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTA_DESCUENTOS",
          label: "Total de descuentos",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  created() {
    moment.locale("es");
  },
  methods: {
    openDocumentDetails(documentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SatDoumentsDetails,
        props: {
          details: documentInformation.DOC_DET,
          typeName: this.typeName,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openDocumentPayments(documentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SatDoumentsPayments,
        props: {
          payments: documentInformation.PAGOS,
          typeName: this.typeName,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openDocumentPayroll(documentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SatDocumentsPayroll,
        props: {
          payment: documentInformation.NOMINA,
          typeName: this.typeName,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateUpdate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_ESTADO_CANC).getTime() || -Infinity) -
          (new Date(a.FECHA_ESTADO_CANC).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_ESTADO_CANC).getTime() || -Infinity) -
          (new Date(b.FECHA_ESTADO_CANC).getTime() || -Infinity)
        );
      }
    },
    sortByDateCertification(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_CER).getTime() || -Infinity) -
          (new Date(a.FECHA_CER).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_CER).getTime() || -Infinity) -
          (new Date(b.FECHA_CER).getTime() || -Infinity)
        );
      }
    },
    async showXML(documentId) {
      try {
        let response = await this.$store.dispatch("GETDOCUMENTXML", {
          documentId: documentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml del documentos`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml del documento`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        if (this.typeName === "Ingreso") {
          allPromises.push(
            this.$store.dispatch("GETDOCUMENTS", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              type: "Ingreso",
            })
          );
        }

        if (this.typeName === "Egreso") {
          allPromises.push(
            this.$store.dispatch("GETDOCUMENTS", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              type: "Egreso",
            })
          );
        }

        if (this.typeName === "Pago") {
          allPromises.push(
            this.$store.dispatch("GETDOCUMENTS", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              type: "Pago",
            })
          );
        }

        if (this.typeName === "Nómina") {
          allPromises.push(
            this.$store.dispatch("GETDOCUMENTS", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              type: "Nómina",
            })
          );
        }

        if (this.typeName === "Traslado") {
          allPromises.push(
            this.$store.dispatch("GETDOCUMENTS", {
              startDate: this.startDate,
              endDate: new Date(
                new Date(this.endDate).getFullYear(),
                new Date(this.endDate).getMonth(),
                new Date(this.endDate).getDate() + 1
              ),
              type: "Traslado",
            })
          );
        }

        await Promise.all(allPromises);
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    formattedDocuments() {
      return this.documents.map((singleDocument) => {
        const singleDocumentWithProps = { ...singleDocument };
        singleDocumentWithProps.statusFormatted =
          singleDocument.STATUS === "CA" ? "Cancelado" : "Vigente";
        singleDocumentWithProps.typeFormatted =
          singleDocument.TIPO === "EG" ? "Egreso" : "Ingreso";
        singleDocumentWithProps.dateFormatted = moment(
          singleDocument.FECHA
        ).format("DD-MM-YYYY HH:mm");
        if (singleDocument.FECHA_CAN) {
          singleDocumentWithProps.cancelationUpdateDateFormatted = moment(
            singleDocument.FECHA_ESTADO_CANC
          ).format("DD-MM-YYYY HH:mm");
        }
        if (singleDocument.FECHA_CER) {
          singleDocumentWithProps.certificationDateFormatted = moment(
            singleDocument.FECHA_CER
          ).format("DD-MM-YYYY HH:mm");
        }
        return singleDocumentWithProps;
      });
    },
    satLink() {
      return this.$store.getters.SAT_LINK;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../style/DocumentsList.css";
</style>
